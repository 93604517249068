import React from 'react';

import { MotionDiv, MotionImage } from '../../animations/components';
import { animationContainer, fadeInRight, fadeInUp } from '../../animations/variants';
import image from '../../assets/images/bread-on-bike.png';
import { ScContainer } from '../../components/container/styled';
import { ScParagraph } from '../../components/Paragraph/styled';
import { ScTitle } from '../../components/Title/styled';
import useContentAnimation from '../../hooks/use-content-animation';

import { ScBikesShopsHeroTextWrapper, ScBikesShopsHeroWrapper } from './styled';
import { useLocation } from '@reach/router';

export const ShopsHero = () => {
	const { ref, controls } = useContentAnimation();

	const location = useLocation();

	React.useEffect(() => {
		if (location.hash) {
			const element = document.querySelector(location.hash);
			if (element) {
				const elementPosition = element.getBoundingClientRect().top - 30;
				window.scrollTo({ top: elementPosition, behavior: 'smooth' });
			}
		}
	}, [location.hash]);

	return (
		<ScContainer>
			<ScBikesShopsHeroWrapper initial="hidden" animate={controls} variants={animationContainer}>
				<MotionImage src={image} alt="bread on bike" variants={fadeInRight} />
				<ScBikesShopsHeroTextWrapper>
					<MotionDiv variants={fadeInUp}>
						<ScTitle centered>Bike shops we roll with</ScTitle>
					</MotionDiv>
					<MotionDiv variants={fadeInUp}>
						<ScParagraph centered>
							<p ref={ref}>
								Take your pick. There are hundreds of bike shops, big and small, that we work with
								all over the country. And you can spend your voucher at any of them. If we don’t
								work with your preferred retailer yet, just get in touch and we’ll get them signed
								up in no time.
							</p>
							<b id="retailer-list">Search for your nearest retailer below.</b>
						</ScParagraph>
					</MotionDiv>
				</ScBikesShopsHeroTextWrapper>
			</ScBikesShopsHeroWrapper>
		</ScContainer>
	);
};
